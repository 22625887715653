import React from "react";
import NavBar from "../components/navBar/NavBar";
import Footer from "../components/footer/footerComponent";
import eng from "../images/eng.png";
import visionImg from "../images/vision-img.png";
import missionImg from "../images/mission-img.png";

import "bootstrap-icons/font/bootstrap-icons.css";

export default function AboutUsPage() {
	return (
		<div>
			{/* Navigation Bar */}
			<NavBar />

			{/* Hero Section */}
			<section className="about-hero bg-light py-3 text-center">
				<div className="container">
					<h1 className="display-4 font-weight-bold animate-fade-in fw-bold">
						About Us
					</h1>
					{/* <p className="lead animate-fade-in">
						Committed to Excellence & Value Creation
					</p> */}
					<hr className="my-4 hr-style mx-auto animate-slide-in" />
				</div>
			</section>

			{/* Image Section */}
			<section className="about-image my-1">
				<div className="container">
					<img
						src={eng}
						className="img-fluid rounded  animate-fade-in"
						alt="Swift Fix Services"
						style={{height: "650px", width: "100%"}}
					/>
				</div>
			</section>

			{/* Who We Are Section */}
			<section className="who-we-are bg-white py-5">
				<div className="container">
					<h2 className="text-center font-weight-bold mb-4 animate-slide-in fw-bold">
						Who We Are
					</h2>
					<p className="text-muted animate-fade-in">
						<bi bi-tools className="me-2"></bi> Swift Fix Services is a Nigerian
						company with globally recognized expertise in providing high-quality
						engineering services. SFS provides comprehensive, dependable, and
						cost-effective consulting, repair, and procurement solutions in
						Nigeria and throughout West Africa. We are backed by a seasoned team
						of experts with an exemplary safety and project management track
						record.
					</p>
					<p className="text-muted animate-fade-in">
						Over the years, we have built a stellar reputation and strong
						relationships, which are testaments to our track record, reputation,
						and the strength of our technical team.
					</p>
				</div>
			</section>

			{/* Mission Section */}
		 {/* Mission Section */}
		 <section className="mission-section mission bg-light py-5 mission-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 animate-move-in-left">
                            <h2 className="font-weight-bold mb-4 fw-bold">
                                Our Mission
                            </h2>
                            <p className="text-muted">
                                Swift Fix Services provides sustainable engineering solutions,
                                products, and services to the construction, energy, offshore, and
                                industrial sectors.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <img
                                src={missionImg}
                                alt="Mission"
                                className="section-img animate-move-in-right"
								height="350px"
								width="100%"
                            />
                        </div>
                    </div>
                </div>
            </section>

			{/* Vision Section */}
			<section className="vision-section vision bg-white py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img
                                src={visionImg}
                                alt="Vision"
                                className="section-img animate-move-in-left"
								height="350px"
								width="100%"
                            />
                        </div>
                        <div className="col-md-6 animate-move-in-right">
                            <h2 className="font-weight-bold mb-4 fw-bold">
                                Our Vision
                            </h2>
                            <p className="text-muted">
                                To be the partner of choice for construction, energy, offshore,
                                and manufacturing industries.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

			{/* Core Values Section */}
			<section className="core-values bg-light py-5 core-values-bg ">
				<div className="container ">
					<h2 className="text-center font-weight-bold mb-4 animate-slide-in fw-bold">
						Our Core Values
					</h2>
					<div className="row">
						<div className="col-md-4 animate-fade-in">
							<h5 className="font-weight-bold">
								<i className="fa-regular fa-face-laugh-beam fa-bounce"></i>{" "} &nbsp;
								 Customer Satisfaction
							</h5>
							<p className="text-muted">
							Customers’ needs are fundamental to our success. 
							To provide the best options in the choice of products and services at affordable rates and fast delivery time.
							</p>
						</div>
						<div className="col-md-4 animate-fade-in">
							<h5 className="font-weight-bold">
								<i className="fa-solid fa-ribbon fa-bounce"></i> &nbsp; Quality
							</h5>
							<p className="text-muted">
							Achieve quality standards in fulfilling our customers’ requirements and satisfaction.
							</p>
						</div>
						<div className="col-md-4 animate-fade-in card-title">
							<h5 className="font-weight-bold">
								<i className="fa-solid fa-people-carry-box fa-fade"></i> &nbsp;
								Community Responsibility
							</h5>
							<p className="text-muted">
							We view our business as an integral part of society.
							 Committed to being a responsible corporate body globally and locally and giving back to the society.
							</p>
						</div>
					</div>
				</div>
			</section>

			{/* Footer */}
			<Footer />
		</div>
	);
}
